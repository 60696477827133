<template>
    <Teleport to="body">
        <transition name="fade">
            <div v-if="isTooltipVisible"
                 :class="`mobile-tooltip-wrapper ${name}-tooltip`"
                 :style="`height: ${ windowHeigth }px`">
                <div class="overlay d-flex align-items-end"
                     @click="closeTooltip">
                    <div class="content"
                         @click.stop>
                        <slot />
                        <div v-if="defaultButton"
                             class="close-button-wrapper d-flex justify-content-center align-items-center w-100">
                            <button class="quiz-button--correct mt-4 w-100"
                                    @click="closeTooltip">
                                <Translate translationKey="quests-game.gotIt"
                                           fallback="Got it" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </Teleport>
</template>

<script setup lang="ts">
    import {
        computed, ref, onMounted, onBeforeUnmount,
    } from 'vue';
    import { useTooltipStore } from '@/js/store/quests/game/modules/TippyMobileStore';
    import { EventTypeEnum } from '@/js/enums/enums';

    const props = defineProps({
        name: {
            type: String,
            required: true,
        },
        defaultButton: {
            type: Boolean,
            required: false,
            default: true,
        },
    });

    const tooltipStore = useTooltipStore();
    const isTooltipVisible = computed(() => tooltipStore.tooltips[props.name]);
    const closeTooltip = () => {
        tooltipStore[`set${props.name.charAt(0).toUpperCase()}${props.name.slice(1)}Tooltip`](false);
    };

    const windowHeigth = ref(window.innerHeight);

    const onResize = () => {
        windowHeigth.value = window.innerHeight;
    };

    onMounted(() => {
        window.addEventListener(EventTypeEnum.RESIZE, onResize);
    });

    onBeforeUnmount(() => {
        window.removeEventListener(EventTypeEnum.RESIZE, onResize);
    });
</script>

<style scoped lang="scss">
@import "@/sass/partials/variables";
@import "@/sass/missions-partials/z-index";

.mobile-tooltip-wrapper {
    display: none;
}

@media (max-width: 768px) {
    .mobile-tooltip-wrapper {
        position: fixed;
        z-index: $tippy-mobile-z-index;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;

        .overlay {
            width: 100%;
            height: 100%;
            background-color: rgba(21, 11, 38, 0.8);
        }

        .content {
            width: 100%;
            padding: 16px;
            background-color: $tippy-mobile-background;
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
            color: $white;
            font-size: 16px;
            font-weight: 500;

            .quiz-button--correct {
                font-weight: 900 !important;
            }
        }
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.3s;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

    .fade-enter-to,
    .fade-leave-from {
        opacity: 1;
    }
}
</style>
